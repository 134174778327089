'use strict';

var Login = require('../login/login');

class LoginNavTabs extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('login-navtabs')) {
            return;
        }

        window.customElements.define('login-navtabs', LoginNavTabs);
    }

    constructor() {
        super();
        this.$navtabs = $(this);
    }

    connectedCallback() {
        Login.registerAccount();
        this.$navtabs.on('click', '.create-account-link', Login.createAccount);
        this.$navtabs.on('click', '.have-account-link', Login.haveAccount);
        $('body').on('click', 'button.create-account-link', Login.triggerCreateAccount);
        $('body').on('click', 'button.have-account-link', Login.triggerHaveAccount);
    }

    disconnectedCallback() {
        this.$navtabs.off('click', '.create-account-link', Login.createAccount);
        this.$navtabs.off('click', '.have-account-link', Login.haveAccount);
        $('body').off('click', 'button.create-account-link', Login.triggerCreateAccount);
        $('body').off('click', 'button.have-account-link', Login.triggerHaveAccount);
    }
}

module.exports = LoginNavTabs;
