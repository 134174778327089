'use strict';

var Login = require('../login/login');

class PWResetForm extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('pwreset-form')) {
            return;
        }

        window.customElements.define('pwreset-form', PWResetForm);
    }

    constructor() {
        super();
        this.$pwreset = $(this);
    }

    connectedCallback() {
        this.$pwreset.on('submit', '.reset-password-form-login', Login.resetPassword);
        this.$pwreset.on('click', '#cancelButton , #login-password-reset', Login.closeModal);
        Login.clearResetForm();
    }

    disconnectedCallback() {
        this.$pwreset.off('submit', '.reset-password-form-login', Login.resetPassword);
        $('#login .modal').off('hidden.bs.modal');
    }
}

module.exports = PWResetForm;
