'use strict';

var formValidation = require('base/components/formValidation');

/**
 * Process the login and register account functionality
 *
 * @param {string} type - type
 * @param {Object} form - form where modal is calling
 */
function processLogin(type, form) {
    var isPDP = $('#nb_is_pdp').length;
    var isPGP = $('#nb_is_pgp').length;
    var isReview = $('#nb_is_review').length;
    var isMyFit = $('.myfit-login').val();
    var isCartLogin = $('#nb_is_cart_login').length;
    var reloadAfterLogin = form.closest('[data-reload-after-login]').length > 0;
    form.spinner().start();
    var url = form.attr('action');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        // eslint-disable-next-line sonarjs/cognitive-complexity
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);

                if (data.passwordReset && data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('form.login-modal').trigger('login:error', data);
                }
            } else {
                if (type === 'login') {
                    var loginFormError = $(form).find('.alert-danger');
                    if (loginFormError.length) {
                        loginFormError.hide();
                    }
                    $(document).trigger('login:success', data);
                }
                if (type === 'registration') {
                    $('form.registration-modal, form.register-embed-checkout').trigger('login:register:success');
                    var optimizely = !!document.querySelector('.optimizely-url');
                    if (optimizely) {
                        window.optimizely = window.optimizely || [];
                        window.optimizely.push({
                            type: 'event',
                            eventName: 'AccountSignUp'
                        });
                    }
                }

                if (reloadAfterLogin) {
                    window.location.reload();
                } else if (isPDP) {
                    var pdpPid = $('.wl-pid-pdp').data('pid');
                    sessionStorage.setItem('pid', pdpPid);
                    window.location.reload();
                } else if (isPGP) {
                    if (isMyFit === 'true') {
                        var myFitredirectUrl = $('.myfit-redirect-url').data('url');
                        window.location.href = myFitredirectUrl;
                    } else {
                        var pgpPid = $('.wl-pid-pgp').data('pid');
                        sessionStorage.setItem('pid', pgpPid);
                        window.location.reload();
                    }
                } else if (isReview || isCartLogin) {
                    window.location.reload();
                } else if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
            }
        },
        error: function (data) {
            if (data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
            } else {
                if (type === 'login') {
                    $('form.login-modal').trigger('login:error', data);
                }
                form.spinner().stop();
            }
        }
    });
}

module.exports = {
    login: function (e) {
        $('form.login-modal, form.login-embed-checkout').trigger('login:submit', e);
        e.preventDefault();
        var form = $(this);
        processLogin('login', form);
        return false;
    },

    register: function (e) {
        e.preventDefault();
        var form = $(this);
        processLogin('registration', form);
        return false;
    },
    closeModal: function () {
        $(document).on('click', '#unique-id-pgp .close, #unique-id-pdp .close', function () {
            sessionStorage.removeItem('pid');
            $('.myfit-login').val('');
        });
    }
};
