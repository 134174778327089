'use strict';

var base = require('base/login/login');
var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
var utils = require('../utils');

const LOGIN_PAGE = '.login-page';
const TC_CHECKBOX = '#terms-conditions';
const TC_ERROR = '.terms-error';
const LOGIN_BLOCK = 'div.have-account';
const REGISTER_BLOCK = 'div.create-account';

(function () {
    // Without extra checks this interval loop would run twice in e.g. the PDP due to multiple files requiring this file.
    // Safe guarding against this via a global until CCT-4728 is done.
    if (window.autofillDetection) {
        return;
    }
    window.autofillDetection = true;

    // This part of code will detect autofill when the page is loading (username and password inputs for example)
    var loading = setInterval(function () {
        $('input').each(function () {
            var labelElem = $(this).siblings('label, i');
            if ($(this).val().length !== '' && !labelElem.hasClass('active')) {
                labelElem.addClass('active');
            }
        });
    }, 250);
    // After 10 seconds we are quite sure all the needed inputs are autofilled then we can stop checking them
    setTimeout(function () {
        clearInterval(loading);
    }, 10000);
}());

/**
 * register user onload function
 */
function registerAccount() {
    setTimeout(function () {
        if ($('.account-tab a').hasClass('active')) {
            $(document).find(LOGIN_BLOCK).show();
            $(document).find(REGISTER_BLOCK).hide();
        }
    }, 500);
} registerAccount();

/**
 * OAuth terms and conditions consent functionality
 */
function OAuthConsentInit() {
    $('#email-subscription').on('change', function () {
        var checked = $(this).prop('checked');
        $('input.email-subscription').prop('checked', checked);
    });

    $('#terms-conditions-oauth').on('change', function () {
        var checked = $(this).prop('checked');
        $('input.terms-conditions').prop('checked', checked);
        $(this).prop('checked', checked);
    });

    $('.register-oauth  button').on('click', function (e) {
        if (!$('#terms-conditions-oauth').prop('checked')) {
            e.preventDefault();
            if ($(TC_ERROR)) {
                $(TC_ERROR).remove();
            }
            var termsErrorMessage = $(TC_CHECKBOX).attr('data-terms-error');
            var termsErrorContent = '<div class="invalid-feedback terms-error" style="display:block"><p class="font-body text-main error">' + termsErrorMessage + '</p></div>';
            $(termsErrorContent).insertAfter('.oauth-form-error');
            $(TC_CHECKBOX + '-oauth').trigger('focus');
            $(this).trigger('login:register:error', termsErrorMessage);
        } else {
            e.preventDefault();
            $(this).closest('.register-oauth').trigger('submit');
        }
    });
} OAuthConsentInit();

module.exports = {
    login: function (e) {
        var form = $(this);
        var submitButton = form.find('.login-submit');
        e.preventDefault();
        var url = form.attr('action');
        submitButton.attr('disabled', 'disabled');
        form.spinner().start();
        form.trigger('login:submit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    if (data.passwordReset && data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        form.trigger('login:error', data.error);
                        submitButton.removeAttr('disabled');
                    }
                } else {
                    $(document).trigger('login:success', data);
                    window.location.href = data.redirectUrl;
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    form.trigger('login:error', data.errorMessage);
                    form.spinner().stop();
                    submitButton.removeAttr('disabled');
                }
            }
        });
        return false;
    },

    register: function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    form.trigger('login:register:error', data);
                    formValidation(form, data);
                } else {
                    var optimizely = !!document.querySelector('.optimizely-url');
                    form.trigger('login:register:success', data);
                    if (optimizely) {
                        window.optimizely = window.optimizely || [];
                        window.optimizely.push({
                            type: 'event',
                            eventName: 'AccountSignUp'
                        });
                    }
                    window.location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                }

                form.spinner().stop();
            }
        });
        return false;
    },

    forgetPassword: function () {
        var isLoginModal = $(this).closest('form').filter('.login-modal, .login-embed-checkout');
        if (isLoginModal.length) {
            var url = $('.modal-reset-password-redirect-url').data('url');
            window.location.href = url;
        } else {
            $('#password-reset-form').modal('show');
        }
    },

    resetPassword: function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        if (utils.isMobile()) {
            url = url.concat(true);
        }

        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    $('.modal-body').html(data.html);
                    $('.reset-password-page').html(data.html);
                    if (data.headerTextForMobile !== null) {
                        $('.password-reset-title').html(data.headerTextForMobile);
                        $('.request-password-title').html(data.headerTextForMobile);
                    }
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    },

    closeModal: function () {
        var isLoginModal = $(this).closest('form').hasClass('email-modal');
        if (isLoginModal) {
            $('#password-reset-form').modal('hide');
        } else {
            window.history.back();
        }
    },

    clearResetForm: base.clearResetForm,

    registerAccount: registerAccount,

    showPassword: function (e) {
        if (e) {
            e.stopImmediatePropagation(); // Avoid calling multiple listeners, see CCT-4728.
        }
        var $this = $(this);
        var passwordId = $this.parent().find('input');
        if ($this.hasClass('show-password')) {
            $(passwordId).attr('type', 'text');
            $this.parent().find('.show-password').hide();
            $this.parent().find('.hide-password').show();
        } else {
            $(passwordId).attr('type', 'password');
            $this.parent().find('.hide-password').hide();
            $this.parent().find('.show-password').show();
        }
    },

    createAccount: function () {
        var $this = $(this);
        $this.closest(LOGIN_PAGE).find(LOGIN_BLOCK).show();
        $this.closest(LOGIN_PAGE).find(REGISTER_BLOCK).hide();
    },

    haveAccount: function () {
        var $this = $(this);
        $(window).scrollTop(0);
        $this.closest(LOGIN_PAGE).find(LOGIN_BLOCK).hide();
        $this.closest(LOGIN_PAGE).find(REGISTER_BLOCK).show();
    },

    triggerCreateAccount: function () {
        $(this).closest('.card').find('.account-tab a').trigger('click');
    },

    triggerHaveAccount: function () {
        $(this).closest('.card').find('.login-tab a').trigger('click');
    },

    serverSideErrorMessage: function () {
        if (!($(this).parents('form').hasClass('registration-modal') && $(this).parents('form').hasClass('login-modal'))) {
            if ($(TC_CHECKBOX).prop('checked') && $(TC_ERROR)) {
                $(TC_ERROR).empty();
            }
            setTimeout(function () {
                var clientError = $(LOGIN_PAGE).find('input').hasClass('is-invalid');
                if (clientError === true) {
                    $('div.alert-danger').remove();
                    if ($('input.is-invalid').first().length > 0) {
                        var scrollPage = $('input.is-invalid').first().offset().top;
                        $(window).scrollTop(scrollPage - 110);
                    }
                }
            }, 500);
        }
    },

    OAuthConsentInit: OAuthConsentInit
};
