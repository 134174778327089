'use strict';

var Login = require('../login/login');
var LoginModal = require('../login/login-modal');

const EMAIL_STORAGE_KEY = 'lastLoginEmailValue';

class LoginForm extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('login-form')) {
            return;
        }

        window.customElements.define('login-form', LoginForm);
    }

    constructor() {
        super();
        this.$login = $(this);
        this.isModal = '';

        this.inputHandler = this.handleInput.bind(this);
    }

    static get observedAttributes() {
        return ['is-modal'];
    }

    attributeChangedCallback(attr, oldValue, newValue) {
        if (attr === 'is-modal') {
            this.isModal = newValue;
        }
    }

    connectedCallback() {
        if (this.isModal === 'true') {
            this.$login.on('submit', 'form.login-modal, form.login-embed-checkout', LoginModal.login);
        } else {
            this.$login.on('submit', 'form.login', Login.login);
        }
        this.$login.on('click', '.hide-show', Login.showPassword);
        this.$login.on('click', '.password-reset', Login.forgetPassword);
        this.$login.on('click', 'button[type="submit"]', Login.serverSideErrorMessage);

        this.initEmailField();
    }

    disconnectedCallback() {
        if (this.isModal) {
            this.$login.off('submit', 'form.login-modal, form.login-embed-checkout', LoginModal.login);
        } else {
            this.$login.off('submit', 'form.login', Login.login);
        }
        this.$login.off('click', '.hide-show', Login.showPassword);
        this.$login.off('click', '.password-reset', Login.forgetPassword);
        this.$login.off('click', 'button[type="submit"]', Login.serverSideErrorMessage);

        this.removeEventListener('input', this.inputHandler);
    }

    initEmailField() {
        const userAgent = (window.navigator.userAgent || '').toLowerCase();
        const isApp = userAgent.indexOf('yappli') > -1;
        const emailField = this.querySelector('#login-form-email');

        // Currently only needed for Yappli app.
        // In a browser environment we can expect the browser to prefill if relevant.
        if (!isApp || !emailField) {
            return;
        }

        let storedValue = '';

        try {
            storedValue = window.localStorage.getItem(EMAIL_STORAGE_KEY);
        } catch (e) {
            // Disabled by user.
        }

        if (storedValue) {
            emailField.value = storedValue;
        }

        this.addEventListener('input', this.inputHandler);
    }

    // eslint-disable-next-line class-methods-use-this
    handleInput(event) {
        const target = event.target;
        const id = target.id;

        if (id !== 'login-form-email') {
            return;
        }

        try {
            window.localStorage.setItem(EMAIL_STORAGE_KEY, target.value);
        } catch (e) {
            // Disabled by user.
        }
    }
}

module.exports = LoginForm;
