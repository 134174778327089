'use strict';

var Login = require('../login/login');
var LoginModal = require('../login/login-modal');

class RegisterForm extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('register-form')) {
            return;
        }

        window.customElements.define('register-form', RegisterForm);
    }

    constructor() {
        super();
        this.$register = $(this);
        this.isModal = '';
    }

    static get observedAttributes() {
        return ['is-modal'];
    }

    attributeChangedCallback(attr, oldValue, newValue) {
        if (attr === 'is-modal') {
            this.isModal = newValue;
        }
    }

    connectedCallback() {
        if (this.isModal === 'true') {
            this.$register.on('submit', 'form.registration-modal, form.register-embed-checkout', LoginModal.register);
        } else {
            this.$register.on('submit', 'form.registration', Login.register);
        }
        this.$register.on('click', '.hide-show', Login.showPassword);
        this.$register.on('click', 'button[type="submit"]', Login.serverSideErrorMessage);
    }

    disconnectedCallback() {
        if (this.isModal === 'true') {
            this.$register.off('submit', 'form.registration-modal, form.register-embed-checkout', LoginModal.register);
        } else {
            this.$register.off('submit', 'form.registration', Login.register);
        }
        this.$register.off('click', '.hide-show', Login.showPassword);
        this.$register.off('click', 'button[type="submit"]', Login.serverSideErrorMessage);
    }
}

module.exports = RegisterForm;
