'use strict';

var Login = require('../login/login');

class OauthRegister extends HTMLElement {
    static init() {
        if (!window.customElements || window.customElements.get('oauth-register')) {
            return;
        }

        window.customElements.define('oauth-register', OauthRegister);
    }

    constructor() {
        super();
        this.$oauth = $(this);
    }

    connectedCallback() {
        this.$oauth.on('click', '.facebook-register', Login.termsAndConditionsFacebook);
    }

    disconnectedCallback() {
        this.$oauth.off('click', '.facebook-register', Login.termsAndConditionsFacebook);
    }
}

module.exports = OauthRegister;
